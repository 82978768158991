import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import NeedAQuote from "../components/need-a-quote";
import Quote from "../components/quote";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Zendesk Integrations"
        description="729 Solutions is a proud partner of Zendesk - reach out to us to get your zendesk instance integrated with your other tools today!"
      />
      <section>
        <div
          className="hero mb-5"
          style={{
            backgroundImage: `url(${patternHex})`,
          }}
        >
          <div className="hero-title bg-dark-transparent">
            <div className="hero-image">
              <StaticImage
                src="../images/clients/zendesk.png"
                alt="Zendesk Implementation Premier Partner"
                layout="constrained"
                style={{ maxWidth: "200px"}}
              />
            </div>
            <h1 className="text-white">
              Integrate Zendesk With Your Other Software
            </h1>
            <p className="text-white">
              Zendesk works best when it is connected to all your other business
              tools. The great news is there are hundreds of apps to do this.
              729 can help you set up and configure these connectors to make
              sure you handle all your workflows efficiently.
            </p>
            <p className="text-white">
              729 can help you integrate with Salesforce, JIRA, Quickbooks, and
              hundreds of other tools. Manage escalations better by having clear
              lines of communication with different teams working with unique
              software.
            </p>
            <CTA
              href="/contact-us/"
              text="Tell us About Your Project"
              className="btn btn-primary"
            />
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2>Whatever the integration, 729 can help you.</h2>
              <p>
                729 can also build completely custom applications to connect any
                other system with an API or even help you build an API if
                needed. We have a team of Zendesk developers who have experience
                building in the new Zendesk V2 app framework.
              </p>
              <p>
                We can build real-time data sync systems for almost anything you
                can think of! If your agents need to see customer data from
                anywhere else let us help you bring it into Zendesk and present
                it in a way that helps them.
              </p>
            </div>
            <div className="col-12 col-lg-6 mt-sm-6 mt-lg-0">
              <h2>Other integrations you might need:</h2>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">Phone systems</span>
                </li>
                <li>
                  <span className="text-dark">Chat tools</span>
                </li>
                <li>
                  <span className="text-dark">Accounting</span>
                </li>
                <li>
                  <span className="text-dark">CRM or sales software</span>
                </li>
                <li>
                  <span className="text-dark">Project management tools</span>
                </li>
                <li>
                  <span className="text-dark">ERPs</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <h2>Recent Zendesk Customers</h2>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/asg.png"
                  alt="10x Genomics"
                />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/kidkraft.png"
                  alt="Accureg"
                />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/n2y.png"
                  alt="Bradley Real Estate"
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/pureflix.png"
                  alt="KidKraft"
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/singularity.png"
                  alt="Learn on demand Systems"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Quote
                quote="We are thrilled, and you bet I will be recommending 729 Solutions to anyone else needing help with their Zendesk integrations!"
                name="Deeanne Akerson"
                company="Kindred Bravely"
              />
            </div>
          </div>
        </div>
      </section>
      <NeedAQuote
        dark
        title="I Need a Quote"
        text="Reach out today. We’ll respond within 24 hours, and can scope your project within 3 days."
        buttonText="Get a Quote"
      />
      <BlogFeed containerClassname="py-6"/>
    </Layout>
  );
};

export default Index;
